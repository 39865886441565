<template>
  <div class="IntelligentPhysical">
    <isHeader ref="isHeader" />
    <div
      ref="scrollBox"
      style="height: 100vh; overflow-y: auto; overflow-x: hidden"
    >
      <commonTop
        :info="{
          title: '智慧体检系统',
          desc: '利用物联网、云计算、大数据、人工智能等技术， 结合国内众多的三甲医院健康体检中心的实际业务流程，专注于高端健康体检软件的研发。',
        }"
        :img="require('@/assets/IntelligentPhysical/banner.png')"
      >
        <!-- 插槽 -->
        <!-- <button>在线咨询</button> -->
        <button @click="$store.commit('changeAppointModal', true)">
          预约体验
        </button>
      </commonTop>
      <!--  -->
      <isTab current="智慧体检系统" :info="info" />
      <isCom />
      <isFooter />
    </div>
  </div>
</template>

<script>
import isHeader from "@/components/header/index.vue";
import isFooter from "@/components/footer/index.vue";
import commonTop from "@/components/commonTop/index.vue";
import isTab from "@/components/ProductTab/tab.vue";
import isCom from "@/components/IntelligentPhysical/com.vue";
export default {
  name: "IntelligentPhysical",
  components: {
    isCom,
    commonTop,
    isHeader,
    isFooter,
    isTab,
  },
  data() {
    return {
      info: {
        partOne: {
          title: "产品设计理念",
          sub: "聚焦业务需求  深度共建智慧体检系统",
          swiper: [
            {
              bg: require("@/assets/IntelligentPhysical/01.png"),
              showBgMark:true,

              icon: `
                            <svg width="100%" height="100%" viewBox="0 0 89 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M70.5671 94.5725H24.5544C23.4588 94.5725 22.3633 93.477 22.3633 92.3814V18.9801C22.3633 17.8846 23.4588 16.7891 24.5544 16.7891H79.8792C80.9748 16.7891 82.0703 17.8846 82.0703 18.9801V57.8719" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M7.02539 23.9099V4.73796C7.02539 3.64242 8.12093 2.54688 9.21647 2.54688H71.1146C72.2101 2.54688 73.3056 3.64242 73.3056 4.73796V16.7889" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M22.3639 86.904H8.6696C7.57406 86.904 6.47852 85.8085 6.47852 84.7129V45.2734" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M33.3184 33.7695H71.1145" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M33.3184 48.0117H71.1145" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M33.3184 61.707H45.3693" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M1 34.3184H12.5032" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M7.02344 28.8398V40.343" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M63.4452 63.8984H56.3242V69.9239C56.3242 77.0449 61.8019 82.5226 68.9229 82.5226C76.044 82.5226 81.5217 77.0449 81.5217 69.9239V63.8984H74.9484" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M68.9238 82.5234V90.1922C68.9238 95.1222 72.7582 98.9566 77.6882 98.9566C82.6181 98.9566 86.4525 95.1222 86.4525 90.1922V85.8101" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            </svg>
                            `,
              name: "体检信息整合利用",
            },
            {
              bg: require("@/assets/IntelligentPhysical/02.png"),
              showBgMark:true,

              icon: `
                            <svg width="100%" height="100%" viewBox="0 0 89 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M77.6577 55.3424H12.3413C11.2527 55.3424 10.1641 54.2538 10.1641 53.1652V4.72214C10.1641 3.63353 11.2527 2.54492 12.3413 2.54492H78.202C79.2906 2.54492 80.3793 3.63353 80.3793 4.72214V53.1652C79.8349 54.2538 78.7463 55.3424 77.6577 55.3424Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M17.2411 80.9238H2.54492V95.62H17.2411V80.9238Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M52.0751 80.9238H37.3789V95.62H52.0751V80.9238Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M86.911 80.9238H72.2148V95.62H86.911V80.9238Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M45 55.3418V80.9241" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M10.1641 80.9246V67.8613H79.8349V80.9246" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M10.709 35.2017H25.4052L30.3039 21.0498L40.1014 41.7334L53.709 15.0625L58.6077 35.2017H72.2153" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            </svg>
                            `,
              name: "临床决策支持",
            },
          ],
        },
        partTwo: {
          title: "产品功能介绍",
          sub: "通过智能化手段赋能健康体检  提高医院健康管理中心的工作效率以及全方位的健康体检服务",
          list: [
            {
              title: "检前个性化体检项目定制",
              desc: "整合健康体检中心运营业务流程，实现健康体检个性化体检定制、一键式在线购买体检服务，随时随地查看健康报告。",
            },
          ],
          right_img: require("@/assets/IntelligentPhysical/swiper_right_img.png"),
        },
      },
    };
  },
  mounted() {
    let that = this;
    this.$refs.scrollBox.addEventListener("scroll", function (e) {
      if (e.target.scrollTop > 80) {
        that.$refs.isHeader.setScrollBg(true);
      } else {
        that.$refs.isHeader.setScrollBg(false);
      }
    });
  },
};
</script>

<style>
</style>