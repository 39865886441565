<template>
  <div class="p_r" v-runshow>
    <img
      class="bg p_r"
      :src="require('@/assets/IntelligentPhysical/bg.png')"
      alt=""
    />
    <div class="ctx p_a">
      <div class="title">客户案例</div>
      <div class="tips"></div>
      <div class="wrap pc_">
        <div
          class="item_"
          v-runcentershow
          v-for="(item, index) in list"
          :key="index"
        >
          <img :src="item.src" alt="" />
        </div>
      </div>
      <!--  -->
      <div class="">
        <div class="mobile_">
          <div class="block">
            <div
              v-runcentershow
              class="ii_swiper swiper"
              style="height: calc(36vw + 20px)"
            >
              <div class="swiper-wrapper" style="">
                <div
                  class="swiper-slide"
                  style="width: 100vw"
                  v-for="(itemMain, indexMain) in listMiddle"
                  :key="indexMain"
                >
                  <div class="flex a_i_c j_c_a">
                    <div
                      v-for="(item, index) in itemMain"
                      :key="index"
                      class="mobile_item_"
                    >
                      <img :src="item.src" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- 如果需要分页器 -->
              <div class="ii_swiper-pagination swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    var mySwiper = new Swiper(".ii_swiper", {
      direction: "horizontal", // 垂直切换选项
      loop: true, // 循环模式选项
      // 如果需要分页器
      pagination: {
        el: ".ii_swiper-pagination",
      },
    });
  },
  data() {
    return {
      list: [
        { src: require("@/assets/IntelligentPhysical/item1.png") },
        { src: require("@/assets/IntelligentPhysical/item5.png") },
        { src: require("@/assets/IntelligentPhysical/item6.png") },
        { src: require("@/assets/IntelligentPhysical/item2.png") },
        { src: require("@/assets/IntelligentPhysical/item3.png") },
        { src: require("@/assets/IntelligentPhysical/item4.png") },
      ],
      listMiddle: [
        [
          { src: require("@/assets/IntelligentPhysical/item1.png") },
          { src: require("@/assets/IntelligentPhysical/item5.png") },
        ],
        [
          { src: require("@/assets/IntelligentPhysical/item6.png") },
          { src: require("@/assets/IntelligentPhysical/item2.png") },
        ],
        [
          { src: require("@/assets/IntelligentPhysical/item3.png") },
          { src: require("@/assets/IntelligentPhysical/item4.png") },
        ],
      ],
    };
  },
};
</script>

<style scoped>
.mobile_item_ {
  width: 36vw;
  /* height: 28vw; */
  background: #fff;
  margin-top: 10px;
}
.mobile_item_ > img {
  width: 100%;
}
.pc_ .item {
  width: 2.76rem;
  height: 2.2rem;
}
.pc_ {
  padding: 0 0;
}
.item_ {
  /* width: calc(20% - 20px); */
  width: 15%;
  /* background: #fff; */
}
.item_ > img {
  width: 100%;
}
.item_:hover {
  transform: translateY(-10px) !important;
  transition: all 0.5s !important;
}
.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  font-size: 20px;
}
.bg {
  z-index: 1;
  background: #051436;
  width: 100%;
  height: 6.29rem;
  object-fit: cover;
  vertical-align: top;
}

.ctx {
  box-sizing: border-box;
  padding: 50px 80px;
  text-align: center;
  color: #fff;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
}
.ctx {
  padding: 1.2rem 1.9rem;
}
.tips {
  margin: 20px auto;
  width: 100px;
  height: 2px;
  background: #1aa858;
  margin-bottom: 4vw;
}
.mobile_ {
  height: 0 !important;
  overflow: hidden;
}

.mobile_ >>> .swiper-pagination-bullet {
  background: #fff !important;
}
.mobile_ >>> .swiper-pagination-bullet-active {
  background: #1aa858 !important;
}
@media screen and (max-width: 1024px) {
  .pc_ {
    display: none;
  }
  .mobile_ {
    height: auto;
    overflow: visible;
  }
  .bg {
    height: 6.81rem;
    object-fit: cover;
    vertical-align: top;
  }
  .ctx {
    padding: 0.96rem 20px;
  }
  .ctx .title {
    font-size: 0.48rem;
    line-height: 0.67rem;
    text-align: center;
  }
  .mobile_ {
    margin-top: 0.96rem;
  }
}
</style>